






import React, { useState, useRef, useEffect } from 'react';
// import './frameNew.css';
import arrowDown from '../../../static/img/arrowDownConvert.png'
import chatSoundIconWhite from '../../../static/img/chatSoundSpeaker.png'
import chatSpeakerMuteIcon from '../../../static/img/chatMuteSpeaker.png'
import chatBotAvatarGala from '../../../static/img/chatBotAvatarGala.png'
import chatBotAvatarDiana from '../../../static/img/chatBotAvatarDiana.png'
import { useAsyncError, useFetcher, useParams } from 'react-router-dom';
import natashaGifCircle from '../../../static/img/circleGif/natashaGifCircle.gif'
import galaGifCircle from '../../../static/img/circleGif/galaGifCircle.gif'
import dianaGifCircle from '../../../static/img/circleGif/dianaGifCircle.gif'
import greenSendIcon from '../../../static/img/greenSendIcon3.png'




// form input with single values and send in api

const FormInputSingle = () => {


    // "form_details" : [
    //           {"label" : "name", "type": "Charfield"},
    //           {"label" : "phone", "type": "Integerfield"},
    //           {"label" : "email", "type": "Emailfield"}
    //       ],



    const apiBaseUrl = 'https://ca1f-103-211-39-22.ngrok-free.app'



    const languages = [
        {
            "language": "Afrikaans",
            "code": "af"
        },
        {
            "language": "Albanian",
            "code": "sq"
        },
        {
            "language": "Amharic",
            "code": "am"
        },
        {
            "language": "Arabic",
            "code": "ar"
        },
        {
            "language": "Armenian",
            "code": "hy"
        },
        {
            "language": "Azerbaijani",
            "code": "az"
        },
        {
            "language": "Basque",
            "code": "eu"
        },
        {
            "language": "Bengali",
            "code": "bn"
        },
        {
            "language": "Bosnian",
            "code": "bs"
        },
        {
            "language": "Bulgarian",
            "code": "bg"
        },
        {
            "language": "Catalan",
            "code": "ca"
        },
        {
            "language": "Chinese (Simplified)",
            "code": "zh-CN"
        },
        {
            "language": "Croatian",
            "code": "hr"
        },
        {
            "language": "Czech",
            "code": "cs"
        },
        {
            "language": "Danish",
            "code": "da"
        },
        {
            "language": "Dutch",
            "code": "nl"
        },
        {
            "language": "English",
            "code": "en"
        },
        {
            "language": "Estonian",
            "code": "et"
        },
        {
            "language": "Finnish",
            "code": "fi"
        },
        {
            "language": "French",
            "code": "fr"
        },
        {
            "language": "Galician",
            "code": "gl"
        },
        {
            "language": "Georgian",
            "code": "ka"
        },
        {
            "language": "German",
            "code": "de"
        },
        {
            "language": "Greek",
            "code": "el"
        },
        {
            "language": "Hebrew",
            "code": "iw"
        },
        {
            "language": "Hindi",
            "code": "hi"
        },
        {
            "language": "Hungarian",
            "code": "hu"
        },
        {
            "language": "Icelandic",
            "code": "is"
        },
        {
            "language": "Indonesian",
            "code": "id"
        },
        {
            "language": "Irish",
            "code": "ga"
        },
        {
            "language": "Italian",
            "code": "it"
        },
        {
            "language": "Japanese",
            "code": "ja"
        },
        {
            "language": "Javanese",
            "code": "jw"
        },
        {
            "language": "Kannada",
            "code": "kn"
        },
        {
            "language": "Kazakh",
            "code": "kk"
        },
        {
            "language": "Khmer",
            "code": "km"
        },
        {
            "language": "Korean",
            "code": "ko"
        },
        {
            "language": "Lao",
            "code": "lo"
        },
        {
            "language": "Latvian",
            "code": "lv"
        },
        {
            "language": "Lithuanian",
            "code": "lt"
        },
        {
            "language": "Macedonian",
            "code": "mk"
        },
        {
            "language": "Malay",
            "code": "ms"
        },
        {
            "language": "Malayalam",
            "code": "ml"
        },
        {
            "language": "Maltese",
            "code": "mt"
        },
        {
            "language": "Mongolian",
            "code": "mn"
        },
        {
            "language": "Nepali",
            "code": "ne"
        },
        {
            "language": "Norwegian",
            "code": "no"
        },
        {
            "language": "Pashto",
            "code": "ps"
        },
        {
            "language": "Persian",
            "code": "fa"
        },
        {
            "language": "Polish",
            "code": "pl"
        },
        {
            "language": "Portuguese",
            "code": "pt"
        },
        {
            "language": "Romanian",
            "code": "ro"
        },
        {
            "language": "Russian",
            "code": "ru"
        },
        {
            "language": "Serbian",
            "code": "sr"
        },
        {
            "language": "Sinhala",
            "code": "si"
        },
        {
            "language": "Slovak",
            "code": "sk"
        },
        {
            "language": "Slovenian",
            "code": "sl"
        },
        {
            "language": "Somali",
            "code": "so"
        },
        {
            "language": "Spanish",
            "code": "es"
        },
        {
            "language": "Sundanese",
            "code": "su"
        },
        {
            "language": "Swahili",
            "code": "sw"
        },
        {
            "language": "Swedish",
            "code": "sv"
        },
        {
            "language": "Tamil",
            "code": "ta"
        },
        {
            "language": "Telugu",
            "code": "te"
        },
        {
            "language": "Thai",
            "code": "th"
        },
        {
            "language": "Turkish",
            "code": "tr"
        },
        {
            "language": "Ukrainian",
            "code": "uk"
        },
        {
            "language": "Urdu",
            "code": "ur"
        },
        {
            "language": "Uzbek",
            "code": "uz"
        },
        {
            "language": "Vietnamese",
            "code": "vi"
        },
        {
            "language": "Welsh",
            "code": "cy"
        },
        {
            "language": "Zulu",
            "code": "zu"
        }
    ]

    const [inputValue, setInputValue] = useState('');
    const [welcomeApiMsg, setWelcomeApiMsg] = useState('');
    const [staticApiVideo, setStaticApiVideo] = useState('');
    const [welcomeApiVideo, setWelcomeApiVideo] = useState('');
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [videoUrl, setVideoUrl] = useState('');
    const [audioToText, setAudioToText] = useState('');
    const [audioBlob, setAudioBlob] = useState(null);
    const [isSoundOn, setIsSoundOn] = useState(true);
    const [showChatBot, setShowChatBot] = useState(false);
    const [recordMessage, setRecordMessage] = useState(false);
    const [isApiCalled, setIsApiCalled] = useState(false);

    const videoRef = useRef(null);
    const messagesEndRef = useRef(null);
    const inputRef = useRef(null);
    const dropdownAvatarRef = useRef(null);
    const dropdownLanguageRef = useRef(null);
    const searchInputRef = useRef(null);
    const [recentBotIndex, setRecentBotIndex] = useState(null);
    const [showWelcomeMsg, setShowWelcomeMsg] = useState(false);
    const [showWelcomeVideo, setShowWelcomeVideo] = useState(true);
    const [showLanguages, setShowLanguages] = useState(false);
    const [audioLanguage, setAudioLanguage] = useState('');
    const [showAvatar, setShowAvatar] = useState(false);
    const [languageCode, setLanguageCode] = useState('en');
    const [isRecordingTemp, setIsRecordingTemp] = useState(false);
    const mediaRecorderRefTemp = useRef(null);
    const recordedChunksRefTemp = useRef([]);
    const mediaStreamRefTemp = useRef(null);
    const [isplayingaudio, setisplayingaudio] = useState(false)
    const audioRef = useRef(null);

    const [isWelcomeVideoReady, setIsWelcomeVideoReady] = useState(false);


    const [isStaticVideo, setIsStaticVideo] = useState(false);
    const [chatBar, setChatBar] = useState(true);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');








//   // taking params from parent app and passing in iframe api

//   const [params, setParams] = useState({
//     companyName: '',
//     moduleName: '',
//     avatarName: ''
//   });

//   useEffect(() => {
//     const receiveMessage = (event) => {

//       const { companyName, moduleName, avatarName } = event.data;
//       console.log('uuuuuuuuuuuuuuuuuuu',companyName, moduleName, avatarName);

//       setParams({ companyName, moduleName, avatarName });

//     };

//     window.addEventListener('message', receiveMessage, false);

//     return () => {
//       window.removeEventListener('message', receiveMessage);
//     };
//   }, []);





//   // taking params from iframe url only and passing in iframe api


//   const params = useLocation();

//   const pathSegments = params.pathname.split('/').filter(segment => segment);

//   const companyName = pathSegments[pathSegments.length - 3];
//   const moduleName = pathSegments[pathSegments.length - 2];
//   const avatarName = pathSegments[pathSegments.length - 1];






















    const audioConstraintsTemp = {
        audio: {
            sampleRate: 16000,
            channelCount: 1,
            echoCancellation: false,
            noiseSuppression: false,
            autoGainControl: false,
        },
    };



    const urlParams = useParams()

    const { companyName, moduleName, avatarName } = urlParams;



    const avatarList = [
        { name: 'Natasha', image: 'image1.png' },
        // { name: 'Gala', image: 'image1.png' },
    ];

    const defaultAvatar = avatarList[0];

    const [selectedAvatar, setSelectedAvatar] = useState({
        name: defaultAvatar.name,
        image: defaultAvatar.image,
    });

    useEffect(() => {
        if (avatarName) {
            const foundAvatar = avatarList.find(avatar => avatar.name.toLowerCase() === avatarName.toLowerCase());
            if (foundAvatar) {
                setSelectedAvatar({
                    name: foundAvatar.name,
                    image: foundAvatar.image,
                });
            } else {
                setSelectedAvatar(defaultAvatar);
            }
        } else {
            setSelectedAvatar(defaultAvatar);
        }
    }, [avatarName]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownAvatarRef.current && !dropdownAvatarRef.current.contains(event.target)) {
                setShowAvatar(false);
            }
        };
        if (showAvatar) {
            document.addEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showAvatar]);

    const handleAvatarToggle = () => {
        setShowAvatar(!showAvatar);
    };

    const handleLanguageSelect = (language) => {
        setShowLanguages(false);
        setLanguageCode(language.code)
    };

    useEffect(() => {
        if (showLanguages) {
            searchInputRef.current.focus();
        }
    }, [showLanguages]);

    const handleAvatarSelect = (avatar) => {
        setSelectedAvatar(avatar);
        setShowAvatar(false);
    };


    useEffect(() => {
        const toggleWelcomeMsg = () => {
            setShowWelcomeMsg(true);
            setTimeout(() => setShowWelcomeMsg(false), 3000);
        };
        toggleWelcomeMsg();
        return () => clearTimeout(toggleWelcomeMsg);
    }, []);


    const formatUrl = (url) => {
        if (url.startsWith('./')) {
            return url.replace('./', '');
        }
        return url;
    };

    const callApiOnPageLoad = async () => {
        const payload = { user_input: 'hi', lang: 'en' };
        try {
            const response = await fetch(
                // `https://avengine.pranathiss.com/api/company/${companyName}/${moduleName}/${avatarName.toLowerCase()}/`,
                // `https://avengine.pranathiss.com/api/company/${companyName}/${moduleName}/${avatarName}/`,
                `${apiBaseUrl}/api/company/${companyName}/${moduleName}/${avatarName.toLowerCase()}/`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(payload),
                }
            );
            if (response.ok) {
                const data = await response.json();
                const newFormatUrl = formatUrl(data.video_path)
                setWelcomeApiMsg(data.responses[0]);
                setStaticApiVideo(data.static_video);
                // setWelcomeApiVideo(data.video_path);    
                // setWelcomeApiVideo(`https://3623-103-211-39-22.ngrok-free.app/${newFormatUrl}`);    
                setWelcomeApiVideo(`${apiBaseUrl}/${newFormatUrl}`);

                setIsWelcomeVideoReady(true); // Mark video as ready
            } else {
                console.error('API error:', response.statusText);
            }
        } catch (error) {
            console.error('Fetch error:', error);
        }
    };

    useEffect(() => {
        callApiOnPageLoad();
    }, [companyName, moduleName, avatarName]);

    useEffect(() => {
        if (isWelcomeVideoReady && videoRef.current) {
            videoRef.current.load();
            videoRef.current.play();
        }
    }, [isWelcomeVideoReady]);

    useEffect(() => {
        const latestBotIndex = messages
            .map((item, index) => (item.type === 'bot' ? index : null))
            .filter(index => index !== null)
            .pop();
        if (latestBotIndex !== null) {
            setRecentBotIndex(latestBotIndex);
        }
    }, [messages]);

    const handleFormSubmit = (event) => {
        event.preventDefault();
        handleSendMessage();
    };

    const handleSendMessage = async () => {
        setInputValue('');
        if (inputValue.trim() || audioToText) {
            setLoading(true);
            setIsApiCalled(true);
            const userMessage = {
                message: audioToText || inputValue.trim() || '',
                type: 'user',
                audioUrl: audioToText,
                isActive: true,
            };
            setMessages((prevMessages) => [...prevMessages, userMessage]);
            const payload = {
                user_input: audioToText ? audioToText : inputValue.trim() || '',
                lang: audioLanguage ? audioLanguage : 'en',
                // lang: 'hi',
                companyName: companyName,
                moduleName: moduleName,
                avatarName: selectedAvatar.name,
            };
            if (messagesEndRef.current) {
                messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
            }
            try {
                // const response = await fetch(`https://avengine.pranathiss.com/api/company/${companyName}/${moduleName}/${avatarName.toLowerCase()}/`, {
                const response = await fetch(`${apiBaseUrl}/api/company/${companyName}/${moduleName}/${avatarName.toLowerCase()}/`, {
                    // const response = await fetch(`https://avengine.pranathiss.com/api/company/${companyName}/${moduleName}/${selectedAvatar.name.toLowerCase()}/`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(payload),
                });
                const data = await response.json();
                setAudioToText('');
                setAudioLanguage('')
                setAudioBlob(null);
                handleResponse(data);

                if (messagesEndRef.current) {
                    messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
                }
            } catch (error) {
                console.error('Error:', error);
            } finally {
                setLoading(false);
            }
        }
    };


    const handleInputFormSubmit = (event) => {
        event.preventDefault();
        let isValid = true;   

        const form = event.target;
        const nameField = form.querySelector('input[name="name"]');
        const nameError = form.querySelector('#nameError');
        const emailField = form.querySelector('input[name="email"]');
        const emailError = form.querySelector('#emailError');
        const phoneField = form.querySelector('input[name="phone"]');
        const phoneError = form.querySelector('#phoneError');

        // Clear previous error messages
        if (nameError) nameError.textContent = '';
        if (emailError) emailError.textContent = '';
        if (phoneError) phoneError.textContent = '';

        if (nameField && !nameField.disabled) {
            // Name validation
            const nameValue = nameField.value.trim();
            if (!nameValue) {
                nameError.textContent = 'Please enter your name.';
                isValid = false;
            }
            if (isValid) {
                setName(nameValue); // Store the name value
                nameField.disabled = true;

                // Add user's response to the chat
                setMessages((prevMessages) => [
                    ...prevMessages,
                    { message: `Name: ${nameValue}`, type: 'user' },
                ]);


                // Show email input by adding a bot message

                setTimeout(() => {

                    setMessages((prevMessages) => [
                        ...prevMessages,
                        {
                            message: (
                                <form onSubmit={handleInputFormSubmit} className='formSingleFields'>
                                    <div className="formInputError">
                                        <input
                                            className="formInputNamePhone"
                                            type="text"
                                            name="email"
                                            placeholder="Enter Email"
                                        />
                                        <span id="emailError" className="contactFormError"></span>
                                    </div>
                                    {/* <button type="submit">Submit email</button> */}
                                    <button type="submit">
                                        {/* <img type="submit" src="/img/chatSendIcon.png" alt="Send" /> */}
                                        <img type="submit" src={greenSendIcon} alt="Send" />
                                    </button>
                                </form>
                            ),
                            type: 'bot',
                        },
                    ]);
                }, 2000);
            }
        } else if (emailField && !emailField.disabled) {
            // Email validation
            const emailValue = emailField.value.trim();
            if (!emailValue) {
                emailError.textContent = 'Please enter your email.';
                isValid = false;
            } else if (!/^\S+@\S+\.\S+$/.test(emailValue)) {
                emailError.textContent = 'Please enter a valid email.';
                isValid = false;
            }
            if (isValid) {                
                setEmail(emailValue); // Store the email value
                emailField.disabled = true;

                // Add user's email response to the chat
                setMessages((prevMessages) => [
                    ...prevMessages,
                    { message: `Email: ${emailValue}`, type: 'user' },
                ]);


                // Show phone input by adding a bot message

                setTimeout(() => {

                    setMessages((prevMessages) => [
                        ...prevMessages,
                        {
                            message: (
                                <form onSubmit={handleInputFormSubmit} className='formSingleFields'>
                                    <div className="formInputError">
                                        <input
                                            className="formInputNamePhone"
                                            type="text"
                                            name="phone"
                                            placeholder="Enter Phone Number"
                                        />
                                        <span id="phoneError" className="contactFormError"></span>
                                    </div>
                                    {/* <button type="submit">Submit phone</button> */}

                                    <button type="submit">
                                        {/* <img type="submit" src="/img/chatSendIcon.png" alt="Send" /> */}
                                        <img type="submit" src={greenSendIcon} alt="Send" />
                                    </button>
                                </form>
                            ),
                            type: 'bot',
                        },
                    ]);
                }, 2000);
            }
        } else if (phoneField && !phoneField.disabled) {
            // Phone number validation
             const phoneValue = phoneField.value.trim();
            if (!phoneValue) {
                phoneError.textContent = 'Please enter your phone number.';
                isValid = false;
            }
            // else if (!/^\d{10}$/.test(phoneValue)) {
            //     phoneError.textContent = 'Please enter a valid phone number.';
            //     isValid = false;
            // }

            else if (!/^\d{9,12}$/.test(phoneValue)) {
                phoneError.textContent = 'Please enter a valid phone number (9 to 12 digits).';
                isValid = false;
            }
            if (isValid) {
                setPhone(phoneValue); // Store the phone value
                phoneField.disabled = true;

                // Add user's phone response to the chat
                setMessages((prevMessages) => [
                    ...prevMessages,
                    { message: `Phone: ${phoneValue}`, type: 'user' },
                ]);

                // Send all the collected data in an API request


                console.log('yyyyyyyyyyyyyyyyyyy',name,email,phoneValue);
                sendApiRequest(name, email, phoneValue);
            }
        }
    };



    const sendApiRequest = (name, email, phone) => {

        const payload = {
            name: name,
            email: email,
            phone: phone,
        };

        // Assuming you're using fetch or axios to send the request
        fetch('/api/save-user-info', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        })
            .then((response) => response.json())
            .then((data) => {
                console.log('Success:', data);
                // Optionally, handle the response from the API (e.g., show success message)
            })
            .catch((error) => {
                console.error('Error:', error);
                // Optionally, handle errors here
            });
        setChatBar(true)
    };





    const handleResponse = (data) => {
        const numberToCheck = "+1 877-768-2786";
        let checkNumber
        if (data) {
            checkNumber = data.responses.some((string) => string.includes(numberToCheck))
        }

        setisplayingaudio(false)
        if (data.responses && data.responses.length > 0 || data.responses && data.responses.length && checkNumber > 0) {
            if (checkNumber) {

                const botMessage = data.responses.join(' ');
                setChatBar(false)
                setMessages((prevMessages) => [
                    ...prevMessages,
                    {
                        message: (
                            <form onSubmit={handleInputFormSubmit} className='formSingleFields'>
                                {/* Name Input */}
                                <div className="formInputError">
                                    <input
                                        className="formInputNamePhone"
                                        type="text"
                                        name="name"
                                        placeholder="Enter Name"
                                        id='nameInput'
                                    />
                                    <span id="nameError" className="contactFormError"></span>
                                </div>

                                {/* <button type="submit">Submit name</button> */}

                                <button type="submit">
                                    {/* <img type="submit" src="/img/chatSendIcon.png" alt="Send" /> */}
                                    <img type="submit" src={greenSendIcon} alt="Send" />
                                </button>
                            </form>

                        ),
                        type: 'bot'
                    },
                ]);
            }
            else {
                const botMessage = data.responses.join(' ');

                setMessages((prevMessages) => [
                    ...prevMessages,
                    { message: botMessage, type: 'bot' },
                ]);
            }
        }
        else {
            setMessages((prevMessages) => [
                ...prevMessages,
                { message: "Could you please repeat?", type: 'bot' },
            ]);
        }
        if (data.audio_data) {
            setisplayingaudio(true)
            // const fullVideoUrl = data.video_path;
            const fullVideoUrl = `${apiBaseUrl}/${formatUrl(data.video_path)}`
            setVideoUrl(fullVideoUrl);
            // setVideoUrl(`${apiBaseUrl}/${fullVideoUrl}`);
            if (videoRef.current) {
                videoRef.current.src = fullVideoUrl;
                videoRef.current.muted = true
                videoRef.current.play();
            }

            const audioBlob = base64ToBlob(data.audio_data, 'audio/mp3');
            const audioUrl = URL.createObjectURL(audioBlob);
            const audioElement = new Audio(audioUrl);
            audioRef.current = audioElement;
            audioElement.muted = !isSoundOn;
            audioElement.play();
            audioElement.addEventListener('ended', () => {
                if (videoRef.current) {
                    videoRef.current.pause();
                    playStaticVideo();
                }
            });
        }

        else if (data.video_path) {
            const fullVideoUrl = `${apiBaseUrl}/${formatUrl(data.video_path)}`
            setVideoUrl(fullVideoUrl);
            if (audioRef.current) {
                audioRef.current.pause();
                audioRef.current.currentTime = 0;
            }
            if (languageCode == 'en') {
                if (videoRef.current) {
                    videoRef.current.src = fullVideoUrl;
                    videoRef.current.play();
                    videoRef.current.removeEventListener('ended', playStaticVideo);
                    videoRef.current.addEventListener('ended', playStaticVideo);
                }
            } else {
                if (videoRef.current) {
                    videoRef.current.src = fullVideoUrl;
                    videoRef.current.muted = true;
                    videoRef.current.play();
                    videoRef.current.removeEventListener('ended', playStaticVideo);
                    videoRef.current.addEventListener('ended', playStaticVideo);
                }
            }
        } else {
            setVideoUrl('');
        }

    };



    const toggleSound = () => {
        setIsSoundOn((prevIsSoundOn) => {
            const newIsSoundOn = !prevIsSoundOn;
            if (audioLanguage === 'en' || showWelcomeVideo) {
                if (videoRef.current) {
                    videoRef.current.muted = !newIsSoundOn;
                }
            } else {
                if (audioRef.current) {
                    audioRef.current.muted = !newIsSoundOn;
                }
            }
            return newIsSoundOn;
        });
    };

    const playStaticVideo = () => {
        if (videoRef.current) {
            videoRef.current.src = staticApiVideo;
            videoRef.current.muted = true;
            videoRef.current.play();
        }
    };

    const base64ToBlob = (base64Data, contentType = '', sliceSize = 512) => {
        const byteCharacters = atob(base64Data);
        const byteArrays = [];
        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        return new Blob(byteArrays, { type: contentType });
    };


    // when removing below code sometimes audio not working 

    useEffect(() => {
        if (videoUrl) {
            const video = videoRef.current;
            if (video) {
                video.onloadeddata = () => {
                    video.onended = () => {
                        setVideoUrl('');
                    };
                };
            }
        }
    }, [videoUrl]);

    useEffect(() => {
        if (audioBlob) {
            sendAudioToApi(audioBlob);
        }
    }, [audioBlob]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setRecordMessage(false);
        }, 3000);
        return () => clearTimeout(timer);
    }, [recordMessage]);

    const handleChatBotOpen = () => {
        setShowChatBot(!showChatBot);
    };

    useEffect(() => {
        setShowChatBot(true);
    }, []);

    useEffect(() => {
        handleSendMessage()
    }, [audioToText])

    useEffect(() => {
        if (isStaticVideo && videoRef.current) {
            videoRef.current.loop = true;
        }
    }, [isStaticVideo]);


    useEffect(() => {
        if (messagesEndRef.current) {
            setTimeout(() => {
                if (messagesEndRef.current) {
                    messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
                }
            }, 500);
        }
    }, [messages, showChatBot]);

    const sendAudioToApi = async () => {
        if (!isRecordingTemp) {
            try {
                mediaStreamRefTemp.current = await navigator.mediaDevices.getUserMedia(audioConstraintsTemp);
                const mimeType = isIOS() || isSafari() ? 'audio/mp4' : 'audio/webm';
                if (!MediaRecorder.isTypeSupported(mimeType)) {
                    console.warn(`${mimeType} is not supported on this browser. Falling back to a supported type.`);
                    return;
                }
                mediaRecorderRefTemp.current = new MediaRecorder(mediaStreamRefTemp.current, { mimeType });
                mediaRecorderRefTemp.current.ondataavailable = (event) => {
                    if (event.data.size > 0) {
                        recordedChunksRefTemp.current.push(event.data);
                    }
                };
                mediaRecorderRefTemp.current.onstop = async () => {
                    const blobTemp = new Blob(recordedChunksRefTemp.current, { type: mimeType });
                    recordedChunksRefTemp.current = [];
                    const readerTemp = new FileReader();
                    readerTemp.readAsDataURL(blobTemp);
                    readerTemp.onloadend = async () => {
                        const base64AudioTemp = readerTemp.result.split(',')[1];
                        const formDataTemp = new FormData();
                        formDataTemp.append('audio_file', base64AudioTemp);
                        formDataTemp.append('file_extension', mimeType === 'audio/mp4' ? 'mp4' : 'webm');
                        try {
                            const responseTemp = await fetch(`https://speech.pranathiss.com/api/vad-base64-speech-to-text-avatar/`, {
                                method: 'POST',
                                body: formDataTemp,
                            });
                            const resultTemp = await responseTemp.json();
                            setAudioToText(resultTemp?.response);
                            setAudioLanguage(resultTemp?.audiolang);
                        } catch (error) {
                            console.error(`Error: ${error.message}`);
                        }
                    };
                };
                mediaRecorderRefTemp.current.start();
                setIsRecordingTemp(true);
            } catch (error) {
                if (error.name === 'NotAllowedError') {
                    console.error('Microphone access was denied. Please enable it in your settings.');
                } else if (error.name === 'NotFoundError') {
                    console.error('No microphone found. Please connect a microphone.');
                } else {
                    console.error(`An unexpected error occurred: ${error.message}`);
                }
            }
        } else {
            mediaRecorderRefTemp.current.stop();
            mediaStreamRefTemp.current.getTracks().forEach((track) => track.stop());
            setIsRecordingTemp(false);
        }
    };

    const isSafari = () => {
        const ua = navigator.userAgent.toLowerCase();
        return ua.includes('safari') && !ua.includes('chrome');
    };

    const isIOS = () => /iPhone|iPad|iPod/i.test(navigator.userAgent);

    return (
        <>
            {
                !showChatBot ?
                    <div className="videochartvalue ">
                        <div className='container' id='ChatBotContainer'>
                            <div className='headAndOutput'>
                                <div className='chatHead'>
                                    <div className='headIcontext'>
                                        <div className='group-2'></div>
                                        <div className='iconHeadChat'>
                                            {
                                                avatarName == 'natasha'
                                                    ?
                                                    <img src="/img/chatAvatarIcon3.png" alt="Avatar" />
                                                    :
                                                    ""
                                            }
                                            {
                                                avatarName == 'gala'
                                                    ?
                                                    <img src={chatBotAvatarGala} alt="Avatar" />
                                                    :
                                                    ""
                                            }
                                            {
                                                avatarName == 'diana'
                                                    ?
                                                    <img src={chatBotAvatarDiana} alt="Avatar" />
                                                    :
                                                    ""
                                            }
                                            <div className='headTextClass'>
                                                <div className="text-wrapper miniHead">Chat with</div>
                                                <div className="text-wrapper-2">AI Assistant</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='headMinClose'>
                                        <div className='languagesDivBoth'>
                                            <div className='languagesDiv' onClick={handleAvatarToggle} ref={dropdownAvatarRef} >
                                                <div className='avatarNameImgClass'>
                                                    {selectedAvatar.name}
                                                </div>
                                                <span className={`arrow ${showAvatar ? 'open' : ''}`}>
                                                    <img src={arrowDown} alt="" className='downArrowClass' />
                                                </span>
                                                {showAvatar && (
                                                    <ul className='languageDropdown'>
                                                        {avatarList
                                                            .filter(avatar => avatar !== selectedAvatar)
                                                            .map((avatar, index) => (
                                                                <li key={index} onClick={() => handleAvatarSelect(avatar)} className='avatarNameImgClass'>
                                                                    {avatar.name}
                                                                </li>
                                                            ))}
                                                    </ul>
                                                )}
                                            </div>
                                        </div>
                                        <div className=' speakerIconButton' >
                                            {isSoundOn ? (
                                                <img src={chatSoundIconWhite} alt="Speaker" onClick={toggleSound} />
                                            ) : (
                                                <img src={chatSpeakerMuteIcon} alt="Speaker" onClick={toggleSound} />
                                            )}
                                            <img src="/img/minimizeIcon/minimize1.png" alt="Line" onClick={handleChatBotOpen} />
                                        </div>
                                    </div>
                                </div>
                                <div id="output-container">
                                    <div className="messages-container">
                                        {
                                            showWelcomeMsg ?
                                                <div className='message-item receiver'>
                                                    <div className='senderIconText'>
                                                        <div className='message'>
                                                            <div className="chat-bubble">
                                                                <div className="typing">
                                                                    <div className="dot"></div>
                                                                    <div className="dot"></div>
                                                                    <div className="dot"></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {
                                                            avatarName == 'natasha'
                                                                ?
                                                                <img src="/img/chatAvatarIcon3.png" alt="Avatar" />
                                                                :
                                                                ""
                                                        }
                                                        {
                                                            avatarName == 'gala'
                                                                ?
                                                                <img src={chatBotAvatarGala} alt="Avatar" />
                                                                :
                                                                ""
                                                        }
                                                        {
                                                            avatarName == 'diana'
                                                                ?
                                                                <img src={chatBotAvatarDiana} alt="Avatar" />
                                                                :
                                                                ""
                                                        }
                                                    </div>
                                                </div>
                                                :
                                                <div className='receiverIconText'>
                                                    {
                                                        avatarName == 'natasha'
                                                            ?
                                                            <img src="/img/chatAvatarIcon3.png" alt="Avatar" />
                                                            :
                                                            ""
                                                    }
                                                    {
                                                        avatarName == 'gala'
                                                            ?
                                                            <img src={chatBotAvatarGala} alt="Avatar" />
                                                            :
                                                            ""
                                                    }
                                                    {
                                                        avatarName == 'diana'
                                                            ?
                                                            <img src={chatBotAvatarDiana} alt="Avatar" />
                                                            :
                                                            ""
                                                    }
                                                    <div className="success-status">
                                                        {welcomeApiMsg}
                                                    </div>
                                                </div>
                                        }


                                        {messages.map((item, index) => (
                                            <div key={index} className={`message-item ${item.type === 'user' ? 'sender' : 'receiver'}`}>
                                                <div className='senderIconText'>
                                                    <div className="message">
                                                        {
                                                            item.audioUrl ?
                                                                recordMessage && index === recentBotIndex ? (
                                                                    <div className="chat-bubble-audio">
                                                                        <div className="typing">
                                                                            <div className="dot"></div>
                                                                            <div className="dot"></div>
                                                                            <div className="dot"></div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                                    : (
                                                                        <p>{item.audioUrl}</p>
                                                                    )
                                                                :
                                                                <p>{item.message}</p>
                                                        }
                                                    </div>
                                                    {item.type === 'user' && <img src="/img/chatSenderIcon.png" alt="Sender Avatar" />}
                                                    {
                                                        avatarName == 'natasha'
                                                            ?
                                                            item.type === 'bot' && <img src="/img/chatAvatarIcon3.png" alt="Receiver Avatar" />
                                                            :
                                                            ""
                                                    }
                                                    {
                                                        avatarName == 'gala'
                                                            ?
                                                            item.type === 'bot' && <img src={chatBotAvatarGala} alt="Receiver Avatar" />
                                                            :
                                                            ""
                                                    }
                                                    {
                                                        avatarName == 'diana'
                                                            ?
                                                            item.type === 'bot' && <img src={chatBotAvatarDiana} alt="Receiver Avatar" />
                                                            :
                                                            ""
                                                    }
                                                </div>
                                                <div ref={messagesEndRef} />
                                            </div>
                                        ))

                                        }




                                        <div ref={messagesEndRef} />
                                    </div>

                                    {/* single video tag start */}

                                    <div className="hello-container">
                                        <video
                                            ref={videoRef}
                                            width="100%"
                                            autoPlay
                                            muted={
                                                showWelcomeVideo && welcomeApiVideo
                                                    ? false
                                                    : (!isApiCalled || !videoUrl || isplayingaudio
                                                        ? true
                                                        : languageCode === "en"
                                                            ? !isSoundOn
                                                            : true)
                                            }
                                            loop={!showWelcomeVideo && (!isApiCalled || !videoUrl)}
                                            onEnded={() => {
                                                if (showWelcomeVideo && welcomeApiVideo) {
                                                    setShowWelcomeVideo(false);
                                                    setVideoUrl(staticApiVideo);
                                                    if (videoRef.current) videoRef.current.load();
                                                } else if (isApiCalled && videoUrl) {
                                                    setIsApiCalled(false);
                                                    setVideoUrl(staticApiVideo);
                                                    if (videoRef.current) videoRef.current.load();
                                                } else {

                                                    if (videoRef.current) videoRef.current.play();
                                                }
                                            }}
                                            playsInline
                                            className={`sharedVideoClass ${!isApiCalled || !videoUrl
                                                ? 'staticVideoClass'
                                                : 'responseVideoClass'
                                                } ${showWelcomeVideo && welcomeApiVideo ? '' : 'staticVideoClass'}`}
                                        >
                                            <source
                                                src={
                                                    showWelcomeVideo && welcomeApiVideo
                                                        ? welcomeApiVideo
                                                        : isApiCalled && videoUrl
                                                            ? videoUrl
                                                            : staticApiVideo
                                                }
                                                type="video/mp4"
                                            />
                                            Your browser does not support the video tag.
                                        </video>
                                    </div>

                                    {/* single video tag end */}

                                </div>
                            </div>
                            <div className='outputHelpfulClass'>
                                {
                                    chatBar ?

                                        <form onSubmit={handleFormSubmit} className="input-container">
                                            <input
                                                type="text"
                                                value={inputValue}
                                                onChange={(e) => setInputValue(e.target.value)}
                                                placeholder="Enter your message"
                                                disabled={loading}
                                                ref={inputRef}
                                            />
                                            {isRecordingTemp ? (
                                                <div className="recording-indicator">
                                                    <div onClick={sendAudioToApi} disabled={loading} className='iconButton'>
                                                        <img src="/img/chatMicSpeakIcon.png" alt="Mic" />
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className='iconButton' onClick={sendAudioToApi} disabled={loading}>
                                                    <img src="/img/chatMicIcon.png" alt="Mic" />
                                                </div>
                                            )}
                                            <div className='iconButton' onClick={handleSendMessage} disabled={loading}>
                                                <img src="/img/chatSendIcon.png" alt="Send" />
                                            </div>
                                        </form>
                                        : ''
                                }
                            </div>
                        </div>
                    </div >
                    :
                    <div className='chatBoxImageClass' >
                        {
                            avatarName == 'natasha'
                                ?
                                <img src={natashaGifCircle} alt="" onClick={handleChatBotOpen} />
                                :
                                ""
                        }
                        {
                            avatarName == 'gala'
                                ?
                                <img src={galaGifCircle} alt="" onClick={handleChatBotOpen} />
                                :
                                ""
                        }
                        {
                            avatarName == 'diana'
                                ?
                                <img src={dianaGifCircle} alt="" onClick={handleChatBotOpen} />
                                :
                                ""
                        }
                    </div>
            }
        </>
    );
};

export default FormInputSingle;


